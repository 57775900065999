/* Main Body */
body {
  min-width: 320px; 
}


html, body, #root, .main-container {
  font-family: 'Quicksand', sans-serif;
  font-size: 1rem;
  margin: 0px;
  min-height: 100vh;
}

p {
  box-sizing: border-box;
  display: block;
  line-height: 2.3rem;
  background-color: white;
  padding: 2rem;
  margin: 2rem;
  letter-spacing: 0.05rem;
  font-size: 1.15rem;
}

.noScroll {
  overflow: hidden;
}

.main-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

button {
  padding: 0.4em 1.1em 0.4em 1.2em ;
  color: white;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s;
  letter-spacing: 0.1em;
  font-size: 0.7rem;
}

button:hover {
  background-color: white;
}

/* Section */

.section {
  background-color: #E0FBFC;
  color: #00778F;
  flex-grow: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 2rem 0 2rem 0;
}

.section button {
  background-color: #00778F;
  border: 1px solid #00778F;
}

.section button:hover {
  background-color: white;
  color: #00778F;
}

.section img {
  width: 100%;
}

.section > div {
  max-width: 1280px;
  width: 100%;
  padding: 0 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section > div > h1 {
  text-align: center;
  font-family: 'Poiret One', cursive;
}

.section.red {
  background-color: #FCE8E0;
  color: #FF334B;
}

.section.red button {
  background-color: #FF334B;
  border: 1px solid #FF334B;
}

.section.red button:hover {
  background-color: white;
  color: #FF334B;
}

.section.yellow {
  background-color: #FCF6E0;
  color: #F2822C;
}

.section.yellow button {
  background-color: #F2822C;
  border: 1px solid #F2822C;
}

.section.yellow button:hover {
  background-color: white;
  color: #F2822C;
}

.sub-section-title {
  box-sizing: border-box;
  text-align: center;
  width: fit-content;
  background-color: white;
  padding: 0.5em 2em 0.5em 2em;
  letter-spacing: 0.05em;
  font-size: 1.7rem;
  font-weight: normal;
}

/* 404 */
.not-found {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100vw;
  transform: translate(-50%, -50%);
}

.not-found > * {
  margin: 0;
}

.not-found button {
  margin: 1em;
  font-size: 1.5em;
}

.not-found h1 {
  font-size: 5rem;
}

.not-found h4 {
  font-size: 1.3rem;
}

@media screen and (max-width: 720px) {
  .section > div > h1 {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 400px) {
  body {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 350px) {
  .section > div {
    padding: 0 1rem 0 1rem;
  }
}
