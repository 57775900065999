div.project {
  box-sizing: border-box;
  width: 16rem;
  height: 20rem;
  background-color: white;
  padding: 1rem;
  position: relative;
  border: 1px solid #FBD0B1;
  border-radius: 3px;
  transition: 0.5s;
}

.project:hover {
  border: #FCF6E0 1px solid;
  background-color: white;
  box-shadow: 0px 10px 18px #d0cab4;
}

.project > :is(div:nth-child(2), div:nth-child(3)) {
  margin: 1rem 0 1rem 0;
}

.project > div:nth-child(1) {
  background-color: red;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  background: rgb(231,231,231);
  background: linear-gradient(90deg, rgba(231,231,231,1) 0%, rgba(255,255,255,1) 100%);
}

.project > div:nth-child(1) img {
  position: absolute;
  top: 0;
  left: 0;
}

.project > div:nth-child(2) {
  font-weight: bold;
}

.project > div:nth-child(3) {
  font-size: 0.9rem;
  text-align: left;
}

.project > div:nth-child(4) {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 360px) {
  .project {
    width: 90%;
  }
}

