.project {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 2rem 0 2rem 0;
  align-items: center;
}

.project > div:first-child {
  flex-basis: 40%;
  overflow: hidden;
  border: 2px solid #FF334B;
  border-radius: 3px;
  width: 100%;
  padding-top: 22.5%;
  height: 0;
  position: relative;
  background: rgb(231,231,231);
  background: linear-gradient(90deg, rgba(231,231,231,1) 0%, rgba(255,255,255,1) 100%);
}

.project > div:first-child > img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.project > div:last-child {
  flex-basis: 60%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project > div:last-child > h2 {
  width: fit-content;
  font-family: 'Poiret One', cursive;
  font-size: min(3vw, 2.3rem);
  margin: 0;
}

.project > div:last-child > p {
  font-size: min(1.5vw, 1.15rem);
  text-align: left;
  line-height: max(min(2.5vw, 2.3rem), 1.5rem);
  margin-top: min(1vw, 1rem);
  margin-bottom: min(1vw, 1rem);
}

.project > div:last-child > button {
  margin: min(1vw, 1rem);
}

@media screen and (max-width: 1024px) {
  .project {
    margin: 1rem 0 1rem 0;
  }

  .project > div:last-child > h2 {
    margin: 1rem;
    font-size: 2.3rem;
  }

  .project > div:last-child > p {
    font-size: min(4vw, 1.15rem);
    line-height: min(6vw, 2.3rem);
    margin: min(1vw, 1rem);
  }
  
  .project > div:first-child {
    overflow: hidden;
    padding-top: 56.25%;
    margin: 1rem 0 0 0;
  }

  .project > div:last-child {
    margin: 1rem 0 0 0;
  }

  .project > div:last-child > button {
    margin-top: 1.5em;
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 450px) {
  p {
    margin: 1rem 0 0 0;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .project > div:last-child > p {
    padding: 2rem;
    line-height: 1.5rem;
    font-size: 1rem;
  }

  .project > div:last-child > h2 {
    font-family: 'Poiret One', cursive;
    font-size: 2rem;
  }

  .project > div:last-child > button {
    font-size: 0.8rem;
  }
}
