.projectCatalog {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, auto));
  grid-gap: 2rem;
  justify-items: center;
  padding: 0.3rem;
  min-height: 20rem;
}

.filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-width: 53rem;
}

.filter > div:last-child > div:first-child {
  text-align: left;
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.filter > div:last-child {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 25rem;
}

.filter > div:last-child > * {
  display: inline-block;
  margin: 0.3rem;
  font-family: 'Quicksand', sans-serif;
  width: 100%;
}

.filter > div:last-child > span {
  font-size: 1.2rem;
  text-align: right;
}

.filter > div:last-child > input, .filter > div:last-child > div > select {
  padding: 0.5rem;
  color: #F2822C;
  border: 1px solid #FBD0B1;
  background-color: white;
  transition: 0.3s;
  box-sizing: border-box;
  outline: none;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  width: 100%;
}

.filter > div:last-child > input:focus, .filter > div:last-child > div > select:focus {
  border: 1px solid white;
}

.filter > div:last-child > div > select {
  padding-right: 30%;
}

.filter > div:last-child > div {
  position: relative;
}

.filter > div:last-child > div::after {
  content: '\2335';
  position: absolute;
  pointer-events: none;
  right: 10px;
  top: 0px;
  padding-top: 5px;
}

.filter > div:last-child > input {
  position: relative;
}

::-webkit-input-placeholder {
  color: #F2822C;
}

:-ms-input-placeholder {
  color: #F2822C;
}

::placeholder {
  color: #F2822C;
}

@media screen and (max-width: 1024px) {
  .filter {
    min-width: 43rem;
  }
}

@media screen and (max-width: 720px) {
  .filter {
    margin-bottom: 1rem;
    min-width: 0;
    justify-content: center;
  }

  .filter > div:first-child > h2 {
    width: 100%;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }

  .filter > div:last-child > span {
    text-align: center;
    display: none;
  }

  .filter > div {
    width: 100%;
  }

  .filter > div:last-child {
    flex-direction: column;
    margin-bottom: 0px;
  }

  .filter > div:last-child > * {
    box-sizing: border-box;
    width: 16rem;
  }

  .filter > div:last-child > div > select {
    padding-right: 0.5rem;
    width: 100%;
  }

  .filter > div:last-child > input {
    box-sizing: border-box;
    width: 16rem;
  }

  .section > div > h1 {
    font-size: 1.6rem;
  }
}