.featuredCatalog {
  display: flex;
  flex-direction: column;
}

.featuredCatalog > div:nth-child(even) {
  flex-direction: row-reverse;
}

@media screen and (max-width: 1024px) {
  .featuredCatalog > :is(div:nth-child(odd), div:nth-child(even)) {
    flex-direction: column;
  }
}