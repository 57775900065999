.header {
  background-color: #E0FBFC;
  display: flex;
  justify-content: center;
}

.header > div {
  display: flex;
  color: #00778F;
  width: 100%;
  justify-content: space-between;
  padding: 2rem 1.5rem 0 1.5rem;
  max-width: 1280px;
}

.header span {
  cursor: pointer;
}

.header > div > div > span {
  height: 100%;
  display: inline-flex;
  align-items: center;
  font-size: 2.5rem;
  letter-spacing: 0.3rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header li {
  list-style-type: none;
}

.header nav li {
  padding: 0 1.5rem 0 1.5rem;
  display: inline-block;
  font-size: 1.4rem;
  transition: 0.5s;
  letter-spacing: 0.1rem;
}

.header a {
  color: #00778F;
  text-decoration: none;
  cursor: pointer;
}

.header nav li:hover {
  transform: scale(1.3);
}

.header nav li:hover > * {
  color: #00AACC;
}

.hamburger {
  display: none;
  min-width: 1.5rem;
  min-height: 1.1rem;
  height: 1.1rem;
  width: 1.5rem;
  align-self: center;
  position: relative;
}

.hamburger input[type=checkbox] {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 0;
  opacity: 0;
  z-index: 3;
  cursor: pointer;
}

.hamburger input[type=checkbox] ~ div {
  transition: 0.3s;
  z-index: 2;
}

.hamburger > input[type=checkbox]:checked ~ div:nth-last-child(4) {
  transform: translate(0px, 7px) rotate(45deg);
}

.hamburger > input[type=checkbox]:checked ~ div:nth-last-child(3) {
  opacity: 0;
}

.hamburger > input[type=checkbox]:checked ~ div:nth-last-child(2) {
  transform: translate(0px, -7px) rotate(-45deg);
}

.hamburger > input[type=checkbox] ~ div:nth-last-child(1) {
  background-color: #E0FBFC;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  position: fixed;
  border-radius: 0;
  z-index: 1;
  transform: translateX(100%);
}

.hamburger > input[type=checkbox]:checked ~ div:nth-last-child(1) {
  transform: translateX(0);
}

.hamburger > input[type=checkbox] ~ div:nth-last-child(1) li {
  display: block;
  padding: 1rem 0 1rem 0;
  font-size: 1.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.hamburger > div {
  width: 100%;
  height: 0.2rem;
  background-color: #00778F;
  border-radius: 1000rem;
}

@media screen and (max-width: 767px) {
  .header > div > div:last-child {
    display: none;
  }

  .header > div > div > span {
    font-size: 2rem;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}