.box {
  width: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s;
}

.box:hover {
  transform: scale(1.2);
  color: #00AACC;
}

.box:hover > div:first-child {
  border: white 2px solid;
  background-color: white;
  box-shadow: 0px 10px 18px #cce7e8;
}

.box > div:first-child {
  background-color: white;
  border: 2px #00778F solid;
  border-radius: 3px;
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}

.box > div:first-child img {
  position: absolute;
  width: 70%;
  height: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.box > span:last-child {
  margin: 0.5rem;
  font-weight: bold;
}