.bio {
  display: flex;
  flex-direction: column;
}

.bio > div {
  margin: 2rem 0 2rem 0;
}

.bio > div:first-child {
  display: flex;
  justify-content: space-between;
}

.bio > div:first-child > div:first-child {
  flex-basis: 30%;
  display: flex;
  align-items: center;
}

.bio > div:first-child > div:first-child > div {
  width: 100%;
  padding-top: 100%;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
}

.bio > div:first-child > div:first-child > div > img {
  position: absolute;
  top: 0;
  left: 0;
}
 
.bio > div:first-child > div:last-child {
  flex-basis: 60%;
  background-color: white;
  text-align: left;
  font-size: 1.15rem;
  display: flex;
  align-items: center;
}

.bio > div:last-child > div:last-child {
  margin: 2rem 0 2rem 0;
}

@media screen and (max-width: 1070px) {
  .bio > div:first-child {
    flex-direction: column;
  }

  .bio > div:first-child > div:first-child {
    justify-content: center;
    margin: 0 0 2rem 0;
  }

  .bio > div:first-child > div:first-child > div {
    width: 50%;
    padding-top: 50%;
  }
}

@media screen and (max-width: 767px) {
  .bio > div:last-child > div:first-child > h2 {
    width: 100%;
    margin-top: 0;
  }

  .bio > div:first-child > div:first-child > div {
    width: 80%;
    padding-top: 80%;
  }
}